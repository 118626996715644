<template>
    <div>
        <div v-show="!tracker.tracker_id && !tracker.isProcessing">
          <div v-if="quoteValidation" class="error-message">
            <h4>**The quote did not pass validation please contact your sales team member**</h4>
          </div>
            <h3>Please enter a PO number(s)</h3>
            <div class="row">
                <div class="col-md-4 col-xs-12 form-group">
                    <label>Customer PO</label>
                    <input class="form-control" type="text" v-model="formFields.distributorPO.val">
                </div>
                <div class="col-md-4 col-xs-12 form-group">
                    <label>Contractor PO</label>
                    <input class="form-control" type="text" v-model="formFields.contractorPO.val">
                </div>
                <div v-if="isStaff" class="col-md-4 col-xs-12 form-group">
                    <label>Sales Order #</label>
                    <input class="form-control" type="text" v-model="formFields.salesOrderNum.val">
                </div>
            </div>
            <div v-show="step1Complete">
                <h3>Ship To</h3>
                <div v-show="!formFields.zip.val">
                    <div class="form-inline spacing_btm">
                        <div class="form-group">
                            <label>Enter Zip</label>
                            <input type="text" v-model="zipSearch" class="form-control" ref="initialZip">
                        </div>
                        <input type="button" @click="addressSearch" value="Next" class="btn btn-default"> or
                        <label><input type="checkbox" v-model="isWillCall" @change="willCall"> Will Call</label>
                    </div>
                    <div v-show="addressSuggestions.length && !addressError">
                        <h4>Select Your City</h4>
                        <div class="row">
                            <div v-for="(suggestion, id) in addressSuggestions" :key="id" class="col-lg-3 col-sm-6 col-xs-12 form-group">
                                <button type="button" @click="setShipTo(suggestion)" class="cdi_clickable btn btn-default form-control">
                                    {{ suggestion.city }} {{ suggestion.state }}, {{ suggestion.zip }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-show="addressError">
                        <p class="text-danger">{{ addressError }}</p>
                    </div>
                    <div v-show="addressContext.length">
                        <h4>Or Select a Previous Address</h4>
                        <div class="row">
                            <div v-for="(context, id) in addressContext" :key="id" class="col-lg-4 col-sm-6 col-xs-12 form-group">
                                <button @click="setShipTo(context)" type="button" class="cdi_clickable btn btn-default form-control form_control_tall">
                                    {{ context.address_name }}<br>
                                    {{ context.address }}<br>
                                    {{ context.city }} {{ context.state }}, {{ context.zip }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="formFields.zip.val">
                    <div class="row">
                        <div class="col-md-6 col-xs-12 form-group">
                            <label>Name</label>
                            <input type="text" v-model="formFields.shipToName.val" class="form-control">
                        </div>
                    </div>
                    <div class="row">
                        <div class="hide_in_mini_shipto col-md-6 col-xs-12 form-group">
                            <label>Address</label>
                            <textarea v-model="formFields.address.val" class="form-control"></textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-xs-12 form-group">
                            <label>City</label>
                            <Typeahead ref="city" cdi_type="city" v-bind:ID.sync="formFields.city.val" />
                        </div>
                        <div class="col-md-4 col-xs-12 form-group">
                            <label>State</label>
                            <select class="form-control" v-model="formFields.state.val">
                                <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{ state }}</option>
                            </select>
                        </div>
                        <div class="col-md-4 col-xs-12 form-group">
                            <label>Zip</label>
                            <input type="text" v-model="formFields.zip.val" class="form-control" ref="zip" @keyup="onZipKeyup">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-xs-12 form-group">
                            <label>Shipping Instructions</label>
                            <textarea class="form-control" v-model="formFields.instructions.val"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="formFields.address.val && step1Complete">
                <h3>Additional Info</h3>
                <div>
                    <div v-if="isStaff">
                        Trucking Options
                        <div class="spacing_left">
                            <label><input type="radio" value="198" v-model="formFields.truckingOptionsID.val"> LTL</label>
                        </div>
                        <div class="spacing_left">
                            <label><input type="radio" value="199" v-model="formFields.truckingOptionsID.val"> Truckload</label>
                        </div>
                        <div class="spacing_left spacing_btm">
                            <label><input type="radio" value="200" v-model="formFields.truckingOptionsID.val"> Will Call</label>
                        </div>
                    </div>

                    <div v-if="isStaff">
                        Delivery Options
                        <div class="spacing_left">
                            <label><input type="checkbox" true-value="1" false-value="0" v-model="formFields.liftGate.val"> Lift Gate</label>
                        </div>
                        <div class="spacing_left">
                            <label><input type="checkbox" true-value="1" false-value="0" v-model="formFields.reqDeliveryDate.val"> Req. Delivery Date</label>
                        </div>
                    </div>
                    <div class="spacing_left spacing_btm">
                        <label><input type="checkbox" true-value="1" false-value="0" v-model="formFields.callAhead.val"> Call Ahead</label>
                    </div>
                    <div v-show="showContact">
                        Contact Info
                        <div class="row">
                            <div class="col-md-4 col-xs-12 form-group">
                                <label>Name</label>
                                <input class="form-control" type="text" v-model="formFields.contactName.val">
                            </div>
                            <div class="col-md-4 col-xs-12 form-group">
                                <label>Phone</label>
                                <input class="form-control" type="text" v-model="formFields.contactPhone.val">
                            </div>
                            <div class="col-md-4 col-xs-12 form-group">
                                <label>Email</label>
                                <input class="form-control" type="text" v-model="formFields.contactEmail.val">
                            </div>
                            <div class="col-md-4 col-xs-12 form-group">
                                <label>Email CC</label>
                                <input class="form-control" type="text" v-model="formFields.contactEmailCC.val">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="isStaff" class="row">
                    <div class="col-md-4 col-xs-12 form-group">
                        <label>Bill To</label>
                        <Typeahead ref="billTo" cdi_type="bill_to" v-bind:ID.sync="formFields.billToID.val" :params="{'params': this.state.object.data.company_id}" @objectSelected="setBillTo" />
                    </div>
                    <div class="col-md-4 col-xs-12">
                        <div v-show="billToAddress">
                            Send Invoice Via: {{ billToAddress.send_method_txt }}<br>
                            {{ billToAddress.address_name }}<br>
                            {{ billToAddress.address }}<br>
                            {{ billToAddress.city }} {{ billToAddress.state }}, {{ billToAddress.zip }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-show="formFields.zip.val && pricing.lead_time.length && step1Complete && formFields.address.val">
                <h3>Ship Day</h3>

                <table class="table">
                    <thead>
                    <tr>
                        <th>Ship Day*</th>
                        <th class="right">Subtotal <span class="spacing_left">${{ (priceFromString(pricing.lead_time[0].total) + this.surchargeTotal).toLocaleString('en') }}</span></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(price, id) in pricing.lead_time" :key="id">
                        <td><label><input type="radio" :value="price.day" v-model="formFields.shipDay.val"> {{ price.ship_date }}</label></td>
                        <td class="right">+ {{ (priceFromString(price.total) - priceFromString(pricing.lead_time[0].total)).toLocaleString()  }}</td>
                    </tr>
                    <tr>
                        <td class="form-inline">
                            <label>
                                <input type="radio" value="-1" v-model="formFields.shipDay.val"> Later Date</label>
                                <DatePicker v-model="formFields.scheduledShip.val" :inputAttributes="{class: 'form-control'}" :editable="formFields.shipDay.val == -1" />
                        </td>

                        <td class="right">+ 0</td>
                    </tr>
                    <tr>
                        <th></th>
                        <th class="right">Total <span class="spacing_left">{{ dayToPrice[formFields.shipDay.val] }}</span></th>
                    </tr>
                    <tr>
                        <td colspan="2"><i>*Does not include shipping/transit time</i></td>
                    </tr>
                    </tbody>
                </table>
                <h3>Verification</h3>
                <div>
                    <label>
                        <input type="radio" value="1" v-model="verifiedRadio"> I approve these drawings for fabrication
                    </label>
                    <br>
                    <label>
                        <input type="radio" value="0" v-model="verifiedRadio"> I still need to verify these drawings
                    </label>
                    <br>
                    <label>
                        <input type="radio" value="-1" v-model="verifiedRadio"> I need CDI to measure
                    </label>
                </div>
            </div>
            <div class="btn-group btn_grp_responsive spacing_top">
                <input type="button" value="Place Order" @click="checkOrder" class="btn btn-primary" :disabled="!canOrder">
                <input type="button" value="Save and Wait for Verification" @click="savePending" class="btn btn-default" :disabled="!canSave">
                <input type="button" value="Cancel" @click="cancel" class="btn btn-default">
              <input type="submit" class="btn btn-default btn-next pull-left" value="Save Progress" @click="saveProgress">
            </div>
        </div>

        <div v-show="tracker.tracker_id">
            <p v-if="tracker.dup_po" class="text-danger">Warning! Duplicate PO</p>
            <p>Order Successful! Your order number is #{{ tracker.tracker_id }}</p>
            <p><router-link :to="`/${trackerLink}/${tracker.tracker_id}`">Continue to Order Page <span class="glyphicon glyphicon-arrow-right"></span></router-link></p>
            <br>
            <div class="panel panel-default">
                <div class="panel-body">
                    <p>Want a text notification when your order ships?</p>

                    <form @submit.prevent="smsSave" class="form-inline">
                        <div class="form-group">
                            <label>Mobile #</label>
                            <input type="text" v-model="tracker.mphone" class="form-control" placeholder="123-456-7890" maxlength="12"/>
                        </div>
                        <button class="btn btn-default" type="submit">Opt in</button>
                    </form>

                    <div class="small">Area code required</div>

                    <br>
                    <div v-show="tracker.smsSaved" class="alert alert-success" role="alert">
                        <span class="glyphicon glyphicon-check"></span> A text will be sent when your order ships.
                    </div>
                </div>
            </div>
        </div>
        <div v-show="tracker.isProcessing">
            Your order is being processed, please wait...
        </div>
      <div v-if="creditCardRequired === 1 && paymentStatus && !creditCardLocked">
        <Confirm ref="orderConfirm" :heading="'CDI Order'" :body="'Once the payment has been processed further changes will require a new quote.\n\n Are you sure you want to place this order?'" @response="invoiceLink" />
      </div>
      <div v-else-if="creditCardRequired === 1 && creditCardLocked">
        <Confirm ref="orderConfirm" :heading="'CDI Order'" :body="'Are you sure you want to place this order? Your payment link will be provided shortly after your order has been created. Once the order has been created further changes will require a new quote.'" @response="addOrder" />
      </div>
      <div v-else>
        <Confirm ref="orderConfirm" :heading="'CDI Order'" :body="'Are you sure you want to place this order?'" @response="addOrder" />
      </div>

    </div>
</template>

<script>
import {store} from '@/store/BusinessObject.store.js';
import Typeahead from '@/components/utils/typeaheads/Typeahead';
import stateCache from '@/cache/state.cache.js';
import DatePicker from '@/components/utils/DatePicker';
import helpers from "@/store/helpers";
import Confirm from '@/components/utils/Confirm';

export default {
    name: "Checkout",
    props: ['distributorID', 'surchargeTotal'],
    components: {
        Typeahead,
        DatePicker,
        Confirm,
    },
    data() {
        return {
            state: store.state,
            creditCardRequired: this.$appStore.state.user.authorization.credit_card_payment_only,
            creditCardLocked: false,
            shipDay: 0,
            completed: false,
            paymentStatus: null,
            quoteValidation: 0,
            validationFailed: false,
            formFields: {
                distributorPO: { val: '', qsField: '[distributor_po]' },
                contractorPO: { val: '', qsField: '[contractor_po]' },
                salesOrderNum: { val: '', qsField: '[sales_order_num]' },
                shipToName: { val: '', qsField: '[shipto][address_name]' },
                address: { val: '', qsField: '[shipto][address]' },
                city: { val: '', qsField: '[shipto][city]' },
                state: { val: '', qsField: '[shipto][state]' },
                zip: { val: '', qsField: '[shipto][zip]' },
                instructions: { val: '', qsField: '[shipto_instructions]' },
                truckingOptionsID: { val: '198', qsField: '[trucking_options_id]' },
                liftGate: { val: 0, qsField: '[liftgate]' },
                reqDeliveryDate: { val: 0, qsField: '[req_delivery_date]' },
                callAhead: { val: 0, qsField: '[call_ahead]' },
                contactName: { val: '', qsField: '[contact_name]' },
                contactPhone: { val: '', qsField: '[contact_phone]' },
                contactEmail: { val: '', qsField: '[contact_email]' },
                contactEmailCC: { val: '', qsField: '[contact_email_cc]' },
                billToID: { val: '', qsField: '[company_bill_to_id]' },
                shipDay: { val: '', qsField: '[ship_day]' },
                scheduledShip: { val: '', qsField: '[scheduled_ship]' },
                measure: { val: '', qsField: '[cdi_to_measure]' },
                checkKey: { val: '', qsField: '[check_key]' },
                ccLock: { val: '', qsField: '[cc_lock]' },

            },
            zipSearch: '',
            isWillCall: false,
            addressSuggestions: [],
            addressContext: [],
            addressError: '',
            pricing: {
                lead_time: [
                    {}
                ],
            },
            cache: {
                stateCache,
            },
            tracker: {
                tracker_id: 0,
                dup_po: false,
                mphone: '',
                smsSaved: false,
                isProcessing: false,
            },
            verifiedRadio: '',
            billToAddress: {},
        }
    },
    methods: {
        onZipKeyup(event) {
            if (event.key === 'Backspace') {
                this.addressSuggestions = [];
                this.formFields.zip.val = '';
                this.$nextTick(() => {
                    this.zipSearch = this.zipSearch.slice(0, -1);
                    this.$refs.initialZip.focus();
                });
            }
        },
        load: function() {
            this.formFields.distributorPO.val = this.state.object.data.distributor_po;
            this.formFields.contractorPO.val = this.state.object.data.contractor_po;
            this.formFields.salesOrderNum.val = this.state.object.data.sales_order_num;
            this.formFields.shipToName.val = this.state.object.data.shipto_name;
            this.formFields.address.val = this.state.object.data.shipto_address;
            this.formFields.city.val = this.state.object.data.shipto_city;
            this.formFields.state.val = this.state.object.data.shipto_state;
            this.formFields.zip.val = this.state.object.data.shipto_zip;
            this.formFields.instructions.val = this.state.object.data.shipto_instructions;
            this.formFields.truckingOptionsID.val = this.state.object.data.trucking_options_id;
            this.formFields.liftGate.val = this.state.object.data.liftgate ? 1 : 0;
            this.formFields.reqDeliveryDate.val = this.state.object.data.req_delivery_date ? 1 : 0;
            this.formFields.callAhead.val = this.state.object.data.call_ahead ? 1 : 0;
            this.formFields.contactName.val = this.state.object.data.contact_name;
            this.formFields.contactPhone.val = this.state.object.data.contact_phone;
            this.formFields.contactEmail.val = this.state.object.data.contact_email;
            this.formFields.contactEmailCC.val = this.state.object.data.contact_email_cc;
            this.formFields.billToID.val = this.state.object.data.company_bill_to_id;
            this.formFields.measure.val = this.state.object.data.cdi_to_measure;
            this.formFields.scheduledShip.val = '';
            this.formFields.shipDay.val = '';
            this.formFields.checkKey.val = '';
            this.formFields.ccLock.val = this.creditCardLocked;

            this.verifiedRadio = this.verifiedValue();

            this.$refs.city.object = this.state.object.data.shipto_city;

            if (this.formFields.state.val && this.formFields.zip.val) {
                this.setShipTo({});
            }

            if (this.isStaff) {
                this.setBillTo();
            }
          this.validateQuote();
          this.ckeckCCLock();
        },
        setBillTo: function() {
            let url = '';
            let params = {};
            if (this.formFields.billToID.val) {
                url =`company/get_bill_to_address/${this.state.object.data.company_id}`;
                params = { 'params[company_billto_id]': this.formFields.billToID.val };
            }else{
                url =`user/get_last_bill_to/${this.state.object.data.distributor_id}`;
            }
            store.apiGeneric(url, params)
                .then(results => {
                    this.$refs.billTo.$data.object = results.address.cdi_address_label;
                    this.formFields.billToID.val = results.address.company_bill_to_id;
                    this.billToAddress = results.address;
                });
        },
        addressSearch: function() {
            if (this.zipSearch.length < 5) {
                return;
            }

            store.apiGeneric('address/from_zip', { 'params[zip]': this.zipSearch, 'params[user_id]': this.distributorID })
                .then(results => {
                    this.addressSuggestions = results.suggestions;
                    this.addressContext = results.context;

                    if (results.suggestions.length === 0) {
                        this.addressError = `We can't find any cities for ${this.zipSearch}. Check your zip and try again.`;
                    } else {
                        this.addressError = '';
                    }
            });
        },
        setShipTo: function(address) {
            if (Object.keys(address).length) {
                this.formFields.shipToName.val = address.address_name;
                this.formFields.address.val = address.address;
                this.formFields.city.val = address.city;
                this.$refs.city.object = address.city; // display for Typeahead
                this.formFields.state.val = address.state;
                this.formFields.zip.val = address.zip;
            }

            store.api('get_pricing_for_address', { 'params[shipto][state]': this.formFields.state.val, 'params[shipto][zip]': this.formFields.zip.val })
                .then(results => {
                    this.pricing = results;
                    this.setShipValue();
                });
        },
        willCall: function() {
            if (this.isWillCall) {
                this.zipSearch = 'Will Call';
                this.addressSearch();
            }else{
                this.zipSearch = '';
                this.addressSuggestions = [];
                this.addressContext = [];
            }
        },
        priceFromString: function(priceStr) {
            if (!priceStr) {
                return 0;
            }
            priceStr = priceStr.replace('$', '');
            priceStr = priceStr.replace(/,/g, '');

            return parseInt(priceStr);
        },
        cancel: function() {
            this.$emit('changeView', {view: 'details'});
            this.zipSearch = '';
            this.isWillCall = false;
            this.addressSuggestions = [];
            this.addressContext = [];
        },
        addOrder: function(confirm) {
            if (confirm) {
                this.tracker.isProcessing = true;
                store.api('add_order', helpers.getQSPairs(this.formFields))
                    .then(results => this.orderResult(results));
            }
        },
        orderResult: function(results) {
          if (this.completed) {
            this.$emit('loadComplete');
          }
          if (!results.tracker_id) {
            this.cancel();
            this.tracker.isProcessing = false;
            this.$appStore.errorBox(results.result);
          } else {
            this.tracker.tracker_id = results.tracker_id;
            this.tracker.dup_po = results.dup_po;
            this.tracker.mphone = results.mphone;
            this.tracker.isProcessing = false;

            if (this.isStaff){
              this.$router.push(`/tracker/${results.tracker_id}`);
            } else {
              this.$router.push(`/myOrder/${results.tracker_id}`);
            }
          }
        },
        checkOrder: function() {
            let errors = this.checkForErrors();
            if (errors.length > 0) {
                this.$appStore.errorBox(errors);

                return;
            }
            if (this.noPrice && this.creditCardRequired === 1) {
              this.$appStore.errorBox('1 or more items do not have a price.');
            }else {
              this.$refs.orderConfirm.open(true);
            }

        },
        checkForErrors: function() {
            for(var i=0; i < this.state.object.data.quote_curbs.length; i++){
                   if(this.state.object.data.quote_curbs[i].quote_pre_measure_sheet_line_id) {
                       if(!this.state.object.data.quote_curbs[i].measured) {
                           return ' Curb measurements must be done before placing an order.';
                       }
                }
            }
            if (this.formFields.shipToName.val == '' || this.formFields.address.val == '' || this.formFields.city.val == '' || this.formFields.state.val == '' || this.formFields.zip.val == '') {
                return 'Missing *required field';
            }
            if (this.formFields.truckingOptionsID.val == 199 && !this.formFields.contactName.val) {
                return 'You have requested a truckload shipment.  Please provide a contact name.';
            }
            if (this.formFields.truckingOptionsID.val == 199 && this.formFields.contactPhone.val.length < 10) {
                return 'You have requested a truckload shipment.  Please provide a contact phone number.';
            }
            if (this.formFields.callAhead.val == 1 && !this.formFields.contactName.val) {
                return 'You have requested a call ahead.  Please provide a contact name.';
            }

            if (this.formFields.callAhead.val == 1 && (this.formFields.contactPhone.val.length < 10 && this.formFields.contactEmail.val.length < 6)) {
                return 'You have requested a call ahead.  Please provide a contact phone number or email.';
            }
            if (!this.verifiedRadio) {
                return 'You must verify your drawings before we can accept your order.';
            }
            if (this.formFields.shipDay.val === ''){
                return 'You must select a ship day before we can accept your order.';
            }
            if (this.formFields.shipDay.val == -1) {
                if (!this.formFields.scheduledShip.val) {
                    return 'Invalid date.';
                }else{
                    let dateParts = this.latestDay.split('-');
                    let latestDayDate = new Date(dateParts[0], dateParts[1]-1, dateParts[2])
                    if (new Date(this.formFields.scheduledShip.val) < latestDayDate){
                        return 'Selected date must be later than displayed ship day options.';
                    }
                }
            }

            return '';
        },
        smsSave: function() {
            if (!this.tracker.mphone) {
                return;
            }

            store.apiGeneric(`tracker/save_sms_notification_number/${this.tracker.tracker_id}`, { 'params[shipping_notification_sms_number]': this.tracker.mphone })
                .then((results) => {
                    if (!results) {
                        this.tracker.smsSaved = false;
                    }else {
                        this.tracker.smsSaved = true;
                    }
                });
        },
        savePending: function() {
            this.formFields.measure.val = 0
            if (this.verifiedRadio == -1) {
                this.formFields.measure.val = 1;
            }
            store.apiWithObjectLoad('save_pending_order', helpers.getQSPairs(this.formFields))
                .then(() => this.$emit('changeView', {view: 'details'}));
        },
        verifiedValue: function() {
            let value = '';
            if (this.state.object.data.drawings_verified) {
                value = 1;
            }else if (this.state.object.data.cdi_to_measure) {
                value = -1;
            }

            return value;
        },
      validateQuote: function() {
        return store.api('validate_quote', helpers.getQSPairs(this.formFields))
            .then((validationResponse) => {
              if (validationResponse && validationResponse.opt === 'order') {
                this.$appStore.errorBox(validationResponse.result);
                // If the response indicates a failed validation, set quoteValidation to 1
                this.quoteValidation = 1;
              } else {
                // If the response is empty or successful, reset quoteValidation to 0
                this.quoteValidation = 0;
              }
            });
      },
      ckeckCCLock: function() {
        return store.apiGeneric('stripe/check_lock')
            .then((response) => {
              if (response && response.opt === 'get') {
                this.creditCardLocked = true;
              }
            });
      },
      invoiceLink: function(confirm) {
        if (confirm && !this.quoteValidation) {
          this.saveProgress()
              .then(() => {
                var result = this.priceFromString(this.dayToPrice[this.formFields.shipDay.val]) - this.priceFromString(this.pricing.lead_time[0].total);
                return store.apiGeneric('stripe/generate_link', {
                  'params[quote_id]': this.state.object.data.quote_id,
                  'params[create_payment]': '1',
                  'params[company_id]': this.state.object.data.company_id,
                  'params[expedited]': result.toFixed(2)
                });
              })
              .then((response) => {
                if (response.opt === 'get') {
                  if (response.data !== 1) {
                    window.location.href = response.data;
                  }
                }
              });
        } else {
          this.validationFailed = true;
        }
      },
      saveProgress: function() {
        return store.api('save_details', helpers.getQSPairs(this.formFields));
      },
      setShipValue: function() {
        const leadTimeArray = this.pricing.lead_time;
        const shipDay = this.state.object.data.ship_day;
        const shipDayNumber = Number(shipDay);

        if (this.state.object.data.ship_day === -2) {
          this.formFields.shipDay.val = this.slowestShip;
        } else  {
          let selectedEntry = leadTimeArray.find(item => item.day === shipDayNumber);
          if (selectedEntry === undefined) {
            selectedEntry = this.slowestShip;
          }
          this.formFields.shipDay.val = selectedEntry.day;
        }
      }
    },
    computed: {
        view: function() {
            return this.state.currentView;
        },
        step1Complete: function() {
            let complete = false;
            if (this.formFields.distributorPO.val.length > 1) {
                complete = true;
            }
            return complete;
        },
        dayToPrice: function() {
            let dayPrice = {};
            for (let i = 0; i < this.pricing.lead_time.length; i++) {
                if (this.pricing.lead_time[i].total) {
                    let total = this.priceFromString(this.pricing.lead_time[i].total);
                    if (this.surchargeTotal) {
                        total += parseInt(this.surchargeTotal);
                    }
                    dayPrice[this.pricing.lead_time[i].day] = '$' + total.toLocaleString('en');
                }
            }
            dayPrice['-1'] = dayPrice[this.slowestShip]; // set a price for the "Later Date" option

            return dayPrice;
        },
        slowestShip: function() {
            let slowestShip = 0;
            for (let i = 0; i < this.pricing.lead_time.length; i++) {
                if (this.pricing.lead_time[i].day > slowestShip) {
                    slowestShip = this.pricing.lead_time[i].day;
                }
            }

            return slowestShip;
        },
        latestDay: function() {
            let latestDay = '';
            for (let i = 0; i < this.pricing.lead_time.length; i++) {
                if (this.pricing.lead_time[i].day == this.slowestShip) {
                    latestDay = this.pricing.lead_time[i].ship_date_raw;
                }
            }
            return latestDay;
        },
        canOrder: function() {
            let canOrder = false;
            if ((this.formFields.shipDay.val || this.formFields.shipDay.val == 0) && this.verifiedRadio == 1){
                canOrder = true;
            }
            return canOrder;
        },
        canSave: function() {
            let canSave = false;
            if ((this.formFields.shipDay.val || this.formFields.shipDay.val == 0) && (this.verifiedRadio == -1 || this.verifiedRadio == '0')){
                canSave = true;
            }
            return canSave;
        },
        showContact: function() {
            let showContact = false;
            if (this.formFields.truckingOptionsID.val == 199 || this.formFields.callAhead.val == 1){
                showContact = true;
            }

            return showContact;
        },
        shipDaySelected: function() {
            return this.formFields.shipDay.val;
        },
        smsPhone: function() {
            return this.tracker.mphone;
        },
        isStaff: function() {
            return this.$appStore.isStaff();
        },
        trackerLink: function() {
            return (this.isStaff ? 'tracker' : 'myorder');
        },
      noPrice() {
        const products = this.state?.object?.data?.pricing?.products || [];
        //Checking to see if any items on the order have the no pricing warning.
        return products.some(item => item.no_price.trim() !== '');
      }
    },
    watch: {
        view: function() {
            if (this.view == 'checkout') {
                this.load();
            }
        },
        shipDaySelected: function() {
            if (this.shipDaySelected !== -1){
                this.formFields.scheduledShip.val = '';
            }
        },
        smsPhone: function() {
            const regexCell = /[^[0-9-]]*/gi;
            this.tracker.mphone = this.tracker.mphone.replace(regexCell, '');
        },
      'state.object.data.payment_status': {
        handler(newValue) {
          this.paymentStatus = newValue;
        },
        immediate: true, // This ensures the watcher is called immediately after the component is mounted
      },
    },
  mounted() {
    const param = (this.$route.query.param) ? this.$route.query.param : undefined;
    if (param !== undefined) {
      this.formFields.checkKey.val = param;
      this.completed = true;
      this.addOrder(true);
    }
  },

}
</script>

<style scoped>
.error-message {
  color: red;
}
</style>