<template>
    <div class="panel panel-default">
        <div class="row panel-body details_container">
                 <div class="col-lg-4 col-sm-6 col-xs-12">
                     <DetailsTypeahead
                         :field="{name: 'distributor_id', label: 'Customer', display: data.distributor_name, cdiType: 'search_my_users', link: userLink, title: userCityZip}"
                         :val="data.distributor_id"
                         @save="save"
                     />
                 </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsLink
                    v-if="isStaff"
                    :field="{name: 'company_id', label: 'Company', display: data.company_name, link: 'company'}"
                    :val="data.company_id"
                    @save="save"
                />
                <DetailsStatic
                    v-else
                    :field="{name: 'company_id', label: 'Company'}"
                    :val="data.company_name"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :field="{name: 'job_tag', label: 'Job Tag'}"
                    :val="data.job_tag"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    v-if="isStaff"
                    :field="{name: 'distributor_po', label: 'Customer PO'}"
                    :val="data.distributor_po"
                    @save="save"
                />
                <DetailsStatic
                    v-else
                    :field="{name: 'distributor_po', label: 'Customer PO'}"
                    :val="data.distributor_po"
                />
            </div>
            <div v-if="isStaff" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsCheckbox
                    :field="{name: 'cdi_to_measure', label: 'CDI to Measure'}"
                    :val="data.cdi_to_measure"
                    @save="save"
                />
            </div>
            <div v-if="isStaff" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :field="{name: 'min_lead_time', label: 'Min Lead Time'}"
                    :val="data.min_lead_time"
                    @save="save"
                />
            </div>
            <div v-if="isStaff" class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsTextarea
                    :field="{name: 'verification_msg', label: 'Verification Message'}"
                    :val="data.verification_msg"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsView
                    :field="{name: 'shipto_complete', label: 'Ship To', view: 'shipToAddress'}"
                    :val="data.shipto_complete"
                    @view="changeView"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsCheckbox
                    :field="{name: 'drawings_verified', label: 'Drawings Verified', display: 'Pre-approve your drawing'}"
                    :val="data.drawings_verified"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsDate
                    :field="{name: 'intended_order_date', label: 'Intended Order Date'}"
                    :val="data.intended_order_date"
                    @save="save"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "@/store/BusinessObject.store";
import DetailsTypeahead from "@/components/businessObjects/utils/DetailsTypeahead";
import DetailsLink from "@/components/businessObjects/utils/DetailsLink";
import DetailsStatic from "@/components/businessObjects/utils/DetailsStatic";
import DetailsText from "@/components/businessObjects/utils/DetailsText";
import DetailsView from "@/components/businessObjects/utils/DetailsView";
import DetailsCheckbox from "@/components/businessObjects/utils/DetailsCheckbox";
import DetailsTextarea from "@/components/businessObjects/utils/DetailsTextarea";
import DetailsDate from "@/components/businessObjects/utils/DetailsDate";

export default {
    name: 'Details',
    components: {
        DetailsTypeahead,
        DetailsLink,
        DetailsTextarea,
        DetailsCheckbox,
        DetailsView,
        DetailsText,
        DetailsStatic,
        DetailsDate
    },
    props: ['data'],
    data() {
        return {
            state: store.state,
            userCityZip: ''
        }
    },
    methods: {
        save: function(data) {
            store.save(data.field, data.val);
        },
        changeView: function(view) {
            this.state.currentView = view;
        }
    },
    computed: {
        isStaff: function() {
            return this.$appStore.isStaff();
        },
        userLink: function() {
            return (this.isStaff ? 'user' : '');
        }
    },
    watch: {
        data:function() {
            let id = this.state.object.data.distributor_id;
            store.customApi('user/get_city_and_zip/'+ id, '', '').then(result => {
                this.userCityZip = result['cityzip'];
            });
        }
    }
}
</script>

<style scoped>

</style>